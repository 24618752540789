import request from "@/utils/request";

export function core_competencies_list(data) {
  return request({
    method: "post",
    url: "competence/list-ki",
    data: data
  });
}

export function post_class_list(data) {
  return request({
    method: "post",
    url: "class/add_class_detail",
    data: data
  });
}

export function save_competencies(data, type) {
  let url = "competence/add-competencies";
  if (type == "edit") url = "competence/update-competencies";
  else if (type == "weight") url = "competence/add-bobot";

  return request({
    method: "post",
    url: url,
    data: data
  });
}

export function delete_competencies(data) {
  return request({
    method: "post",
    url: "competence/delete-competencies",
    data: data
  });
}

export function get_teacher() {
  return request({
    method: "GET",
    url: "class/get-teacher"
  });
}

export function get_lesson_ki(data) {
  return request({
    method: "POST",
    url: "lesson/lesson-ki",
    data: data
  });
}

export function get_weight(data) {
  return request({
    method: "POST",
    url: "competence/list-bobot",
    data: data
  });
}
